<template>
  <el-dialog
    append-to-body
    width="500px"
    title="新增下单地区"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item label="城市" prop="area_arr">
        <el-cascader
          :popper-append-to-body="false"
          popper-class="areaCascaderWrap"
          style="width:100%;"
          v-model="form.data.area_arr"
          :options="areaArr"
          :props="{ checkStrictly: true }"
          :disabled="isChange">
        </el-cascader>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'serveAreaEdit',
    props: ['areaArr'],
    data() {
      return {
        token: '',
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            area_arr: [],
            province: '',
            city: '',
            rate: '',
          },
          rules: {
            area_arr: [{ required: true, message: '必填项', trigger: 'change' }],
          }
        },
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true;
        common.deepClone(this.form.data, row)
        this.form.data.area_arr = [row.province,row.city]
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = { ...this.form.data}
            let _area_arr = this.form.data.area_arr;
            _params.province = _area_arr[0];
            _params.city = _area_arr[1];
            delete _params.area_arr
            let apiUrl = !this.isChange ? '/admin/flex_service_district/add' : '/admin/flex_service_district/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
    }
  }
</script>

<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="城市:" prop="area_arr">
          <el-cascader
            style="width:100%;"
            v-model="table.params.area_arr"
            :options="areaArr"
            :props="{ checkStrictly: false }"
            filterable
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="showEdit">添加</el-button>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:province="{row}">
        <span>{{row.province_name}}-{{row.city_name}}</span>
      </template>
      <template v-slot:status="{row}">
        <el-switch
          v-model="row.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
          @change="handleStatus(row)">
        </el-switch>
      </template>
    </VTable>

    <edit ref="edit" :areaArr="areaArr" @refresh="getTable"></edit>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import { mixinTable } from '@/mixins/table.js';
export default {
  name: 'AreaList',
  components: {
    VTable,
    Edit,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "province", label: "城市", hidden: false },
        { name: "count", label: "入驻机构数", showTooltip: true, hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "create_time", label: "操作时间", fixed:"right", width: "200", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          province: '',
          city: '',
          area_arr: [],
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      areaArr: [],
    }
  },
  created() {
    this.getTable();
    this.getArea();
  },
  methods: {
    getTable() {
      let _params = {
        ...this.table.params,
      }
      if(!!_params.area_arr) {
        _params.province = _params.area_arr[0];
        _params.city = _params.area_arr[1];
      }
      delete _params.area_arr;
      this.$http.get('/admin/flex_service_district/list', { params: _params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 切换状态
    handleStatus(row) {
      this.$http.post('/admin/flex_service_district/operation', {id:row.id}).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
        } else {
          this.$message.error(res.data)
        }
      }).finally(() => {
        this.getTable();
      })
    },
    
  }
}
</script>